import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Input } from 'antd';
import style from '@/src/pages/Subscriptions/Subscriptions.module.scss';

const PauseModal = props => {
  const {
    isPauseAll,
    showPauseModal,
    setShowPauseModal,
    pausedFor,
    setPausedFor,
    pausedQty,
    setPausedQty,
    handlePauseItem,
    itemInfo,
    isSkip
  } = props;

  if (isSkip) {
    // setPausedFor(1);
    setPausedQty(itemInfo.qty);
  }
  return (
    <Modal
      title={`${
        isSkip
          ? 'PULAR CICLO'
          : `${isPauseAll ? 'PAUSAR ASSINATURA' : 'PAUSAR ITEM'}`
      }`}
      visible={showPauseModal}
      width={400}
      cancelButtonProps={{
        className: 'ant-btn-secondary',
        onClick: () => setShowPauseModal(false)
      }}
      className="centered-footer"
      okText="Confirmar"
      onOk={handlePauseItem}
      maskClosable
      onCancel={() => setShowPauseModal(false)}
      okButtonProps={{
        disabled: isPauseAll
          ? !pausedFor || pausedFor === 0
          : !pausedFor || !pausedQty || pausedQty === 0 || pausedFor === 0
      }}
    >
      <div className={style.pauseModal}>
        {!isPauseAll ? (
          <>
            {isSkip && (
              <p
                className={style.pauseModal__title}
                style={{ margin: '0 0 15px 0', lineHeight: 1.5 }}
              >
                Tem certeza que deseja pular {pausedFor > 0 ? pausedFor : ''}{' '}
                {pausedFor > 1 ? 'ciclos' : 'ciclo'} desse produto?
              </p>
            )}
            <p
              className={`${
                isSkip ? style.pauseModal__subtitle : style.pauseModal__title
              }`}
            >
              Produto:
            </p>
            <span style={{ fontSize: 12 }}>{itemInfo.name}</span>
          </>
        ) : (
          <>
            <p className={style.pauseModal__title}>
              Tem certeza que deseja pausar todos os itens?{' '}
            </p>
          </>
        )}

        <div className={style.pauseModal__input}>
          <p style={{ marginBottom: 8 }}>Ciclos em pausa</p>
          <Input
            type="number"
            onChange={e => setPausedFor(Number(e.target.value))}
            placeholder="Ex.: 1"
            min={1}
            value={pausedFor}
          />
        </div>
        {/* {!isPauseAll && (
          <div className={style.pauseModal__input}>
            <p>Unidades do produto</p>
            <Row type="flex" justify="space-between" align="middle">
              <Col span={16}>
                <Slider
                  style={{ width: '100%' }}
                  // onChange={v => console.log(v)}
                  onChange={value => setPausedQty(Number(value))}
                  // onChange={(v) => console.log(v)}
                  min={0}
                  max={itemInfo.canPauseQty}
                  defaultValue={itemInfo.pausedQty}
                  type="number"
                  value={pausedQty}
                />
              </Col>
              <Col span={6}>
                <Input
                  style={{ width: 80 }}
                  onChange={e => setPausedQty(Number(e.target.value))}
                  // onChange={(v) => console.log(v)}
                  defaultValue={itemInfo.pausedQty}
                  value={pausedQty}
                  type="number"
                  min={0}
                  max={itemInfo.canPauseQty}
                  disabled={isSkip}
                />
              </Col>
            </Row>
          </div>
        )} */}
      </div>
    </Modal>
  );
};

PauseModal.propTypes = {
  isPauseAll: PropTypes.bool.isRequired,
  showPauseModal: PropTypes.bool.isRequired,
  isSkip: PropTypes.bool.isRequired,
  setShowPauseModal: PropTypes.func.isRequired,
  pausedFor: PropTypes.number.isRequired,
  setPausedFor: PropTypes.func.isRequired,
  pausedQty: PropTypes.number.isRequired,
  setPausedQty: PropTypes.func.isRequired,
  handlePauseItem: PropTypes.func.isRequired,
  itemInfo: PropTypes.objectOf(PropTypes.any).isRequired
};

export default PauseModal;
