import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Col, Row, Card, Input, Tooltip, Icon, Switch } from 'antd';

import Tables from '@/src/components/Tables';

import { setHubSkus as setHubSkusState } from '@/src/store/modules/product-details/slice';

import usePrevState from '@/src/hooks/usePrevState';

const SkuCard = ({
  data,
  updateInfo,
  loading,
  editPermission,
  salechannel
}) => {
  const dispatch = useDispatch();

  const [hubData, setHubData] = useState([]);
  const [hubSkus, setHubSkus] = useState([]);

  const [allVisible, setAllVisible] = useState(false);
  const [allIfoodVisible, setAllIfoodVisible] = useState(false);

  const [disabledZn, setDisabledZn] = useState(true);
  const [disabledPetz, setDisabledPetz] = useState(true);
  const [disabledIfood, setDisabledIfood] = useState(true);

  const { salechannels: salechannelsList } = useSelector(
    state => state.products
  );

  const { product } = useSelector(state => state.productDetails);

  const prevSalechannelState = usePrevState(salechannel);

  useEffect(() => {
    const newHubData = data?.hubSkus.map(hub => ({
      ...hub,
      editPermission
    }));
    setHubData(newHubData);

    const hubSkusAttr = data.hubSkus.map(item => ({
      id: item.id,
      visible: item.visible,
      ifood_visible: item.ifoodVisible,
      hub_workflow: item.hubWorkflow
    }));
    setHubSkus(hubSkusAttr);
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (hubSkus.length > 0) {
      if (disabledZn === disabledPetz) {
        setAllVisible(hubSkus.filter(item => !item.visible).length === 0);
      } else if (disabledZn) {
        const petzHubs = hubSkus.filter(item => item.hub_workflow === 'petz');
        setAllVisible(
          petzHubs.length > 0 &&
            petzHubs.filter(item => !item.visible).length === 0
        );
      } else if (disabledPetz) {
        const znHubs = hubSkus.filter(
          item => item.hub_workflow === 'conventional'
        );
        setAllVisible(
          znHubs.length > 0 && znHubs.filter(item => !item.visible).length === 0
        );
      }
      setAllIfoodVisible(
        hubSkus.filter(item => !item.ifood_visible).length === 0
      );
    } else {
      setAllVisible(false);
      setAllIfoodVisible(false);
    }

    dispatch(setHubSkusState(hubSkus));
    // eslint-disable-next-line
  }, [hubSkus]);

  useEffect(() => {
    // console.log(prevSalechannelState, salechannel);
    if (!prevSalechannelState) return;

    const ifoodId = salechannelsList.find(
      item => item.attributes.name.toLowerCase() === 'ifood'
    )?.attributes.id;
    const petzId = salechannelsList.find(
      item => item.attributes.name.toLowerCase() === 'petz'
    )?.attributes.id;
    const zeenowId = salechannelsList.find(
      item => item.attributes.name.replace('.', '').toLowerCase() === 'zeenow'
    )?.attributes.id;

    const hubSkusAttr = data.hubSkus.map(item => ({
      id: item.id,
      visible:
        item.hubWorkflow === 'petz'
          ? salechannel.includes(petzId)
          : salechannel.includes(zeenowId),
      ifood_visible: salechannel.includes(ifoodId),
      hub_workflow: item.hubWorkflow
    }));

    setHubSkus(hubSkusAttr);
    // eslint-disable-next-line
  }, [salechannel]);

  useEffect(() => {
    if (salechannelsList.length > 0) {
      if (product.saleChannelIds.length > 0) {
        const ifoodId = salechannelsList.find(
          item => item.attributes.name.toLowerCase() === 'ifood'
        )?.attributes.id;
        const petzId = salechannelsList.find(
          item => item.attributes.name.toLowerCase() === 'petz'
        )?.attributes.id;
        const zeenowId = salechannelsList.find(
          item =>
            item.attributes.name.replace('.', '').toLowerCase() === 'zeenow'
        )?.attributes.id;

        setDisabledZn(!product.saleChannelIds.includes(zeenowId));
        setDisabledPetz(!product.saleChannelIds.includes(petzId));
        setDisabledIfood(!product.saleChannelIds.includes(ifoodId));
      } else {
        setDisabledZn(true);
        setDisabledPetz(true);
        setDisabledIfood(true);
      }
    }
  }, [salechannelsList, product]);

  const handleAllVisible = value => {
    const hubSkusAttr = hubSkus.map(item => ({
      ...item,
      visible:
        item.hub_workflow === 'petz'
          ? value && !disabledPetz
          : value && !disabledZn
    }));
    setHubSkus(hubSkusAttr);
  };

  const handleAllIfoodVisible = value => {
    const hubSkusAttr = hubSkus.map(item => ({
      ...item,
      ifood_visible: value
    }));
    setHubSkus(hubSkusAttr);
  };

  const handleSkuVisible = (value, id) => {
    const hubSkusAttr = hubSkus.map(item => {
      if (item.id === id) {
        return {
          ...item,
          visible: value
        };
      }
      return item;
    });
    setHubSkus(hubSkusAttr);
  };

  const handleSkuIfoodVisible = (value, id) => {
    const hubSkusAttr = hubSkus.map(item => {
      if (item.id === id) {
        return {
          ...item,
          ifood_visible: value
        };
      }
      return item;
    });
    setHubSkus(hubSkusAttr);
  };

  const columns = [
    {
      title: 'Hub Sku Id',
      align: 'left',
      key: 'hub_sku_id',
      render: record => <span>{record?.id || '-'}</span>
    },
    {
      title: 'Hub',
      align: 'left',
      key: 'hub_name',
      render: record => <span>{record?.hubName || '-'}</span>
    },
    {
      title: 'Estoque',
      align: 'left',
      key: 'hub_stock',
      render: record => (
        <span>
          {record?.availableQuantity > 0 ? 'Disponível' : 'Indisponível'}
        </span>
      )
    },
    // {
    //   title: 'Preço',
    //   align: 'center',
    //   key: '2',
    //   dataIndex: '',
    //   render: record => {
    //     if (record?.hub_price?.list_price) {
    //       return (
    //         <span>{`${formatCurrency(record.hub_price.list_price)}${
    //           record.hub_price.table_name ? ` (${record.hub_price.table_name})` : ''
    //         }`}</span>
    //       );
    //     }
    //     return <>-</>;
    //   }
    // },
    {
      title: (
        <div
          style={{
            width: 180,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span>Visibilidade App</span>
            <Tooltip
              placement="top"
              overlayClassName="tooltip-table"
              title="Este controle torna o sku visível no app para o hub selecionado. Para habilitar a edição do campo, selecione ao menos um canal de venda nos Detalhes do Produto. Após editar, clique em Salvar Alterações"
            >
              <Icon type="info-circle" style={{ marginLeft: '5px' }} />
            </Tooltip>
          </div>
          <Switch
            disabled={
              (disabledPetz &&
                hubSkus.filter(item => item.hub_workflow === 'conventional')
                  .length === 0) ||
              (disabledZn &&
                hubSkus.filter(item => item.hub_workflow === 'petz').length ===
                  0)
            }
            checked={allVisible}
            onChange={handleAllVisible}
          />
        </div>
      ),
      align: 'center',
      width: 210,
      key: 'app_visibility',
      render: record => {
        return (
          <Switch
            checked={hubSkus.find(item => item.id === record.id)?.visible}
            onChange={value => handleSkuVisible(value, record.id)}
            disabled={record.hubWorkflow === 'petz' ? disabledPetz : disabledZn}
          />
        );
      }
    },
    {
      title: (
        <div
          style={{
            width: 180,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span>Visibilidade iFood</span>
            <Tooltip
              placement="top"
              overlayClassName="tooltip-table"
              title="Este controle torna o sku visível no iFood para o hub selecionado. Para habilitar a edição do campo, selecione ao menos um canal de venda nos Detalhes do Produto. Após editar, clique em Salvar Alterações"
            >
              <Icon type="info-circle" style={{ marginLeft: '5px' }} />
            </Tooltip>
          </div>
          <Switch
            disabled={disabledIfood}
            checked={allIfoodVisible}
            onChange={handleAllIfoodVisible}
          />
        </div>
      ),
      align: 'center',
      width: 210,
      key: 'ifood_visibility',
      render: record => {
        return (
          <Switch
            checked={hubSkus.find(item => item.id === record.id)?.ifood_visible}
            onChange={value => handleSkuIfoodVisible(value, record.id)}
            disabled={disabledIfood}
          />
        );
      }
    }
  ];

  return (
    <Col className="no-shadow" style={{ marginBottom: 7 }}>
      <Card title="SKUs" className="no-shadow">
        <Row>
          <Card
            className="no-shadow no-border"
            title={data?.attributes?.name}
            style={{ maxWidth: 950 }}
            loading={loading}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-end',
                marginBottom: '25px'
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  position: 'relative'
                }}
              >
                <p style={{ marginBottom: 3, fontWeight: 'bold' }}>
                  Nome no App
                  <Tooltip placement="right" title="Titulo do SKU">
                    <Icon style={{ marginLeft: 6 }} type="info-circle" />
                  </Tooltip>
                </p>
                <Input
                  placeholder="Nome"
                  defaultValue={data?.name}
                  onChange={elem =>
                    // changeSKUName(elem.target.value, 'skuName', data.id)
                    updateInfo({
                      newValue: elem.target.value,
                      type: 'skuName',
                      skuId: data.id,
                      infos: data
                    })
                  }
                  disabled={!editPermission}
                />
                <span
                  className="erp_desc"
                  style={{
                    position: 'absolute',
                    bottom: '-30px',
                    whiteSpace: 'nowrap'
                  }}
                >
                  Nome ERP: {data?.externalTitle}
                </span>
              </div>
              <div className="input-title-border">
                <p>ID</p>
                <Input value={data?.externalId} disabled />
              </div>
              <div className="input-title-border">
                <p>ERP</p>
                <Input value={data?.externalReference} disabled />
              </div>
              <div className="input-title-border">
                <p>EAN</p>
                <Input value={data?.ean} disabled />
              </div>
              <div className="input-title-border">
                <p>Peso</p>
                <Input value={`${data?.weight || '?'} Kg`} disabled />
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                // justifyContent: 'space-between',
                // alignItems: 'flex-end',
                // flexDirection: 'column',
                marginTop: 42
              }}
            >
              <p
                style={{ marginRight: 8, marginBottom: 0, fontWeight: 'bold' }}
              >
                SKU Assinável
                {/* <Tooltip placement="right" title="Titulo do SKU">
                  <Icon style={{ marginLeft: 6 }} type="info-circle" />
                </Tooltip> */}
              </p>
              <Switch
                defaultChecked={data?.subscriptable}
                style={{ maxWidth: 44 }}
                onChange={value =>
                  updateInfo({
                    newValue: value,
                    type: 'subscriptable',
                    skuId: data.id,
                    infos: data
                  })
                }
              />
            </div>
            <Tables
              columnsDefaults={columns}
              data={hubData}
              className="even-gray"
              count={4}
              visiblePaginationUp={false}
              visiblePaginationDown={false}
            />
          </Card>
        </Row>
      </Card>
    </Col>
  );
};

SkuCard.defaultProps = {
  updateInfo: () => {},
  loading: true,
  salechannel: false
};

SkuCard.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  updateInfo: PropTypes.func,
  loading: PropTypes.bool,
  editPermission: PropTypes.bool.isRequired,
  salechannel: PropTypes.bool
};

export default SkuCard;
