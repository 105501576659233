import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';

import ModalConfirm from '@/src/components/Hubs/Modals/Confirm';

import usePermissions from '@/src/hooks/usePermissions';
import * as modules from '@/src/globals/permissionsModules';
import moment from '@/src/services/moment';
// import { buildQueryFromUrl } from '@/src/utils/buildQueryObj';
import CyclesTable from '@/src/components/Subscriptions/Modals/CyclesTable/index';
import SubscriptionItemsTable from '@/src/components/Subscriptions/SubscriptionItemsTable/index';
import StatusPill from '@/src/components/StatusPill';
import {
  getSubscription,
  getCycles,
  pauseSubscriptionItem,
  // unpauseAllSubscriptionItems,
  pauseAllSubscriptionItems,
  requestCancelSubscription,
  cancelSubscription,
  setIsLoading,
  retryCycleBilling,
  editSubscription
} from '@/src/store/modules/subscriptions/slice';
import {
  Row,
  Col,
  Breadcrumb,
  Card,
  Button,
  Menu,
  Dropdown,
  Icon,
  Tooltip
} from 'antd';
import Audit from '@/src/components/Audit/index';

// Modals
import CancelSubscriptionModal from '@/src/components/Subscriptions/Modals/CancelSubscriptionModal/index';
import FrequencyModal from '@/src/components/Subscriptions/Modals/FrequencyModal/index';
import PauseModal from '@/src/components/Subscriptions/Modals/PauseModal/index';
import CreditCardModal from '@/src/components/Subscriptions/Modals/CreditCardModal/index';
import AddressModal from '@/src/components/Subscriptions/Modals/AddressModal/index';

import style from '../Subscriptions.module.scss';

const Subscription = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [confirmModalData, setConfirmModalData] = useState(false);

  const [showAudit, setShowAudit] = useState(false);

  const [hasPausedItem, setHasPausedItem] = useState(false);
  const [isPauseAll, setIsPauseAll] = useState(false);

  const [isEdit, setIsEdit] = useState(false);

  // Cancel
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [isCodeCancelModal, setIsCodeCancelModal] = useState(false);
  const [codeToCancel, setCodeToCancel] = useState('');

  // Pause & Skip
  const [showPauseModal, setShowPauseModal] = useState(false);
  const [pausedFor, setPausedFor] = useState(0);
  const [pausedQty, setPausedQty] = useState(0);
  const [itemInfo, setItemInfo] = useState({});
  const [isSkip, setIsSkip] = useState(false);

  // Update Frequency & Date - Modal
  const [showFrequencyModal, setShowFrequencyModal] = useState(false);
  const [editNewBillingDate, setEditNewBillingDate] = useState('');
  const [editNewFrequency, setEditNewFrequency] = useState(0);
  const [subscriptionInterval, setSubscriptionInterval] = useState(15);
  const [subscriptionNextBilling, setSubscriptionNextBilling] = useState();

  // Update Credit Card - Modal
  const [showCreditCardModal, setShowCreditCardModal] = useState(false);
  const [editNewCreditCard, setEditNewCreditCard] = useState({});

  // Update Address - Modal
  const [showAddressesModal, setShowAddressesModal] = useState(false);
  const [editNewAddress, setEditNewAddress] = useState({});

  // Update Items
  const [editNewItems, setEditNewItems] = useState([]);

  // Permissions
  const { editPermission } = usePermissions();
  const managePermission = editPermission(modules.SUBSCRIPTIONS_MANAGE);

  // Get Cycles
  const getCyclesHandler = useCallback(
    payload => {
      if (id) dispatch(getCycles({ ...payload, id }));
    },
    [dispatch, id]
  );
  useEffect(() => {
    if (id) {
      dispatch(getSubscription(id));
      getCyclesHandler({ query: { page: 1, perPage: 10 } });
    }
    // eslint-disable-next-line
  }, [id]);

  const {
    isLoading,
    subscription: subscriptionData,
    subscriptionAddress: address,
    subscriptionCreditCard: creditCard,
    subscriptionCustomer: customer,
    subscriptionItems: products
  } = useSelector(state => {
    return state.subscriptions;
  });

  const setDefaultValues = () => {
    setSubscriptionInterval(subscriptionData.interval);
    setEditNewFrequency(subscriptionData.interval);
    setSubscriptionNextBilling(subscriptionData.next_billing_date);
    setEditNewBillingDate(subscriptionData.next_billing_date);

    setEditNewCreditCard(creditCard);

    setEditNewAddress(address);

    const items = products.map(product => {
      return {
        id: product.attributes.id,
        quantity: product.attributes.quantity
      };
    });
    setEditNewItems(items);
  };
  useEffect(() => {
    setDefaultValues();
    // eslint-disable-next-line
  }, [subscriptionData]);

  // Subscription Actions
  const actions = (
    <Menu>
      <Menu.Item key="hist-att" onClick={() => setShowAudit(true)}>
        Histórico de atualizações
      </Menu.Item>
    </Menu>
  );
  const userActions = (
    <>
      <Dropdown overlay={actions} trigger={['click']}>
        <span>
          <Icon type="ellipsis" key="ellipsis" style={{ fontSize: '30px' }} />
        </span>
      </Dropdown>
    </>
  );

  // useEffect(() => {
  //   const paused = products.filter(
  //     product => product.attributes.paused_quantity > 0
  //   );
  //   setHasPausedItem(paused.length > 0);
  // }, [products]);

  // const handleUnpauseItems = () => {
  //   if (!hasPausedItem) {
  //     setShowPauseModal(true);
  //     setIsPauseAll(true);
  //   } else {
  //     setShowConfirmModal(true);

  //     setConfirmModalData({
  //       title: 'Despausar Itens',
  //       body: `Tem certeza que deseja despausar todos os itens?`,
  //       okText: 'Despausar',
  //       onOk: () => {
  //         dispatch(unpauseAllSubscriptionItems(subscriptionData.id));
  //         setIsLoading(true);
  //         setTimeout(() => {
  //           dispatch(getSubscription(id));
  //         }, 500);

  //         setShowConfirmModal(false);
  //       }
  //     });
  //   }
  // };

  // Handle Pause & Skip
  const handlePauseItem = () => {
    if (isPauseAll) {
      dispatch(
        pauseAllSubscriptionItems({
          paused_for: pausedFor,
          id: subscriptionData.id
        })
      );
      setHasPausedItem(true);
      setIsPauseAll(false);
    } else {
      dispatch(
        pauseSubscriptionItem({
          paused_for: pausedFor,
          paused_quantity: pausedQty,
          subscriptionId: subscriptionData.id,
          item_id: itemInfo.itemId
        })
      );
      setIsSkip(false);
    }
    setShowPauseModal(false);
    setIsLoading(true);
    setTimeout(() => {
      dispatch(getSubscription(id));
    }, 500);
  };

  // Handle Cancel
  const handleCancel = () => {
    dispatch(cancelSubscription({ id, code: codeToCancel }));
    setShowCancelModal(false);
    setTimeout(() => {
      dispatch(getSubscription(id));
    }, 500);
  };
  const handleRequestCancel = () => {
    dispatch(requestCancelSubscription(Number(id)));
    setIsCodeCancelModal(true);
  };

  // Handle Retry Billing
  const handleRetryBilling = cycleId => {
    setShowConfirmModal(true);

    setConfirmModalData({
      title: 'Retentar Cobrança',
      body: `Tem certeza que deseja retentar a cobrança?`,
      okText: 'Confirmar',
      onOk: () => {
        dispatch(retryCycleBilling(cycleId));
        setIsLoading(true);
        setTimeout(() => {
          dispatch(getSubscription(id));
        }, 500);

        setShowConfirmModal(false);
      }
    });
  };

  // Handle Edit Subscription
  const handleSaveEdit = () => {
    setShowConfirmModal(true);

    setConfirmModalData({
      title: 'Editar Assinatura',
      body: `Tem certeza que deseja salvar edição?`,
      okText: 'Confirmar',
      onOk: () => {
        dispatch(
          editSubscription({
            id,
            name: subscriptionData.name,
            address_street: editNewAddress.street,
            zipcode: editNewAddress.zipcode,
            address_number: editNewAddress.number,
            address_city: editNewAddress.city,
            address_country: editNewAddress.country,
            address_state: editNewAddress.state,
            address_district: editNewAddress.district,
            address_complement: editNewAddress.complement,
            address_latitude: editNewAddress.latitude,
            address_longitude: editNewAddress.longitude,
            place_id: editNewAddress.place_id,
            credit_card_id: editNewCreditCard.id,
            interval: editNewFrequency,
            next_billing_date: editNewBillingDate,
            subscription_items: editNewItems
          })
        );
        setIsLoading(true);
        setIsEdit(false);
        setTimeout(() => {
          dispatch(getSubscription(id));
        }, 500);

        setShowConfirmModal(false);
      }
    });
  };

  return (
    <>
      <Audit
        id={subscriptionData.id || ''}
        title="ASSINATURA"
        type="Subscription"
        visible={showAudit}
        setVisible={setShowAudit}
      />

      <ModalConfirm
        data={confirmModalData || {}}
        visible={showConfirmModal}
        setVisible={setShowConfirmModal}
      />

      <CancelSubscriptionModal
        handleCancel={handleCancel}
        handleRequestCancel={handleRequestCancel}
        showCancelModal={showCancelModal}
        setShowCancelModal={setShowCancelModal}
        setCodeToCancel={setCodeToCancel}
        codeToCancel={codeToCancel}
        setIsCodeCancelModal={setIsCodeCancelModal}
        isCodeCancelModal={isCodeCancelModal}
      />

      <CreditCardModal
        showCreditCardModal={showCreditCardModal}
        setShowCreditCardModal={setShowCreditCardModal}
        customer={customer}
        setEditNewCreditCard={setEditNewCreditCard}
        editNewCreditCard={editNewCreditCard}
        subscriptionCreditCard={creditCard}
      />

      <AddressModal
        showAddressesModal={showAddressesModal}
        setShowAddressesModal={setShowAddressesModal}
        customer={customer}
        editNewAddress={editNewAddress || {}}
        setEditNewAddress={setEditNewAddress}
        subscriptionAddress={address}
        // setSubscriptionAddress={setSubscriptionAddress}
      />

      <FrequencyModal
        showFrequencyModal={showFrequencyModal}
        setShowFrequencyModal={setShowFrequencyModal}
        editNewBillingDate={editNewBillingDate || ''}
        setEditNewBillingDate={setEditNewBillingDate || ''}
        editNewFrequency={editNewFrequency || 0}
        setEditNewFrequency={setEditNewFrequency}
        subscriptionInterval={subscriptionInterval || 15}
        subscriptionNextBilling={subscriptionNextBilling || ''}
        setSubscriptionInterval={setSubscriptionInterval}
        setSubscriptionNextBilling={setSubscriptionNextBilling}
      />

      <PauseModal
        isPauseAll={isPauseAll}
        showPauseModal={showPauseModal}
        setShowPauseModal={setShowPauseModal}
        pausedFor={pausedFor}
        setPausedFor={setPausedFor}
        pausedQty={pausedQty}
        setPausedQty={setPausedQty}
        itemInfo={itemInfo}
        isSkip={isSkip}
        handlePauseItem={handlePauseItem}
      />

      <Row type="flex" justify="space-between" align="middle">
        <Col span={20}>
          <h1>
            {subscriptionData?.name} - {customer?.name} -{' '}
            {subscriptionData?.current_cycle}º ciclo
          </h1>
        </Col>
      </Row>

      <Breadcrumb separator="" style={{ marginBottom: 24 }}>
        <Breadcrumb.Item>
          <Link to="/assinaturas">Assinaturas</Link>
          <Breadcrumb.Separator>&gt; </Breadcrumb.Separator>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{subscriptionData.name}</Breadcrumb.Item>
      </Breadcrumb>

      <div
        style={{
          borderRadius: '16px',
          border: '7px solid #ebebeb',
          minHeight: '48px'
        }}
      >
        <Card
          className="no-shadow"
          title={`${!isEdit ? 'DETALHES DA ASSINATURA' : 'EDITAR ASSINATURA'}`}
          // extra={id ? actionsExtra : null}
          extra={userActions}
        >
          <Row
            type="flex"
            align="middle"
            justify="space-between"
            style={{ position: 'relative' }}
          >
            <Col span={18}>
              <Row type="flex">
                <Col className={style.infoBox}>
                  <p className={style.infoBox__title}>Endereço</p>
                  <Row
                    style={
                      (address?.street !== editNewAddress?.street ||
                        address?.zipcode !== editNewAddress?.zipcode) &&
                      isEdit
                        ? { borderColor: '#33d6ef' }
                        : {}
                    }
                    className={style.infoBox__content}
                  >
                    {isEdit && (
                      <Button
                        className={style.infoBox__edit}
                        onClick={() => setShowAddressesModal(true)}
                      >
                        <Icon type="edit" />
                      </Button>
                    )}

                    {/* {isEdit ? (
                      <>
                        {editNewAddress?.nickname && (
                          <span style={{ fontWeight: 'bold' }}>
                            {editNewAddress?.nickname}
                          </span>
                        )}
                      </>
                    ) : (
                      <>
                        {address?.nickname && (
                          <span style={{ fontWeight: 'bold' }}>
                            {address?.nickname}
                          </span>
                        )}
                      </>
                    )} */}

                    <span style={{ fontWeight: 'bold' }}>
                      {isEdit ? editNewAddress?.street : address?.street},{' '}
                      {isEdit ? editNewAddress?.number : address?.number}{' '}
                    </span>
                    {isEdit ? (
                      <span>
                        {editNewAddress?.city} - {editNewAddress?.state} -{' '}
                        {editNewAddress?.zipcode}{' '}
                      </span>
                    ) : (
                      <span>
                        {address?.city} - {address?.state} - {address?.zipcode}{' '}
                      </span>
                    )}
                  </Row>
                </Col>
                <Col className={style.infoBox}>
                  <p className={style.infoBox__title}>Cartão</p>
                  <Row
                    style={
                      creditCard.id !== editNewCreditCard.id && isEdit
                        ? { borderColor: '#33d6ef' }
                        : {}
                    }
                    className={style.infoBox__content}
                  >
                    {isEdit && (
                      <Button
                        className={style.infoBox__edit}
                        onClick={() => setShowCreditCardModal(true)}
                      >
                        <Icon type="edit" />
                      </Button>
                    )}
                    <span style={{ fontWeight: 'bold' }}>
                      XXXX XXXX XXXX{' '}
                      {isEdit ? editNewCreditCard?.number : creditCard.number}
                    </span>
                    <span>
                      Nome: {isEdit ? editNewCreditCard?.name : creditCard.name}
                    </span>
                    <span>
                      Bandeira:{' '}
                      {isEdit
                        ? editNewCreditCard?.card_type
                        : creditCard.card_type}
                    </span>
                    <span>
                      Expiração:{' '}
                      {isEdit
                        ? editNewCreditCard?.expiration
                        : creditCard.expiration}
                    </span>
                  </Row>
                </Col>
                <Col span={8} className={style.infoBox__details}>
                  <Row className={style.infoBox__details_frequency}>
                    Frequência:
                    {isEdit ? (
                      <>
                        <Col
                          type="flex"
                          span={6}
                          style={{
                            marginRight: 4,
                            marginLeft: 4,
                            width: 'fit-content'
                          }}
                        >
                          <Button onClick={() => setShowFrequencyModal(true)}>
                            {subscriptionInterval}
                            <Icon style={{ marginLeft: 8 }} type="edit" />
                          </Button>
                        </Col>
                        <span>dias </span>
                      </>
                    ) : (
                      <> {subscriptionData?.interval} dias</>
                    )}
                  </Row>
                  <span>
                    Próxima cobrança:{' '}
                    {isEdit ? (
                      <span
                        style={
                          subscriptionNextBilling !==
                          subscriptionData.next_billing_date
                            ? { fontWeight: 'bold', color: '#33d6ef' }
                            : {}
                        }
                      >
                        {moment(subscriptionNextBilling).format('DD/MM/YYYY')}
                      </span>
                    ) : (
                      moment(subscriptionData?.next_billing_date).format(
                        'DD/MM/YYYY'
                      )
                    )}
                  </span>
                  <span>
                    Data de criação:{' '}
                    {moment(subscriptionData?.created_at).format('DD/MM/YYYY')}
                  </span>
                  <span>ID: #{subscriptionData.id}</span>
                </Col>
              </Row>
            </Col>

            <Col
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                justifyContent: 'space-between',
                height: '100%'
              }}
              className={style.infoBox__actions}
            >
              <Row style={{ margin: '-15px 0 10px' }}>
                <StatusPill status={subscriptionData.status} />
              </Row>
              {isEdit ? (
                <Row type="flex" gutter={8}>
                  <Col>
                    <Button
                      onClick={() => handleSaveEdit()}
                      type="primary"
                      disabled={!managePermission}
                    >
                      Salvar
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      onClick={() => {
                        setIsEdit(!isEdit);
                        setDefaultValues();
                      }}
                      type="secondary-copy"
                    >
                      Cancelar
                    </Button>
                  </Col>
                </Row>
              ) : (
                <Row type="flex" gutter={8}>
                  <Col>
                    <Tooltip
                      // defaultVisible={!subscriptionData?.canCancelSubscription}
                      overlayStyle={
                        subscriptionData?.canCancelSubscription
                          ? { display: 'none' }
                          : {}
                      }
                      placement="top"
                      title="Você atingiu o limite de cancelamento de 10 assinaturas na data de hoje. Entre em contato com seu superior."
                    >
                      <Button
                        onClick={() => {
                          // setShowConfirmModal(true);

                          setShowCancelModal(true);
                          setIsCodeCancelModal(false);
                        }}
                        disabled={
                          subscriptionData.status === 'inactive' ||
                          !managePermission ||
                          !subscriptionData?.canCancelSubscription
                        }
                        type="danger"
                      >
                        Cancelar
                      </Button>
                    </Tooltip>
                  </Col>
                  <Col>
                    <Button
                      onClick={() => setIsEdit(true)}
                      type="secondary-copy"
                      disabled={
                        subscriptionData.status === 'inactive' ||
                        !managePermission
                      }
                    >
                      <Icon type="edit" />
                    </Button>
                  </Col>
                </Row>
              )}

              {/* {(!isEdit && subscriptionData.status !== 'inactive') && (
                <Row style={{ marginTop: 15 }}>
                  <Button
                    onClick={handleUnpauseItems}
                    className={`${style.infoBox__actions_pause}`}
                  >
                    {hasPausedItem ? 'Despausar Itens' : 'Pausar Itens'}
                  </Button>
                </Row>
              )} */}
            </Col>
          </Row>

          <SubscriptionItemsTable
            products={products}
            setIsSkip={setIsSkip}
            setIsPauseAll={setIsPauseAll}
            setShowPauseModal={setShowPauseModal}
            isEdit={isEdit}
            subscriptionData={subscriptionData}
            isLoading={isLoading}
            hasPausedItem={hasPausedItem}
            setItemInfo={setItemInfo}
            setEditSubscriptionItems={setEditNewItems}
          />
          <Col>
            <h3
              className="ant-card-head-title"
              style={{ margin: '42px 0 0', padding: 0 }}
            >
              Ciclos da assinatura
            </h3>
            <CyclesTable
              handleRetryBilling={e => handleRetryBilling(e)}
              populateCyclesTable={getCyclesHandler}
            />
          </Col>
        </Card>
      </div>
    </>
  );
};

export default Subscription;
