import React from 'react';
import { Link } from 'react-router-dom';
import { Menu, Dropdown, Icon } from 'antd';
import PropTypes from 'prop-types';

const Actions = ({ setShowAudit, setAuditId, id }) => {
  return (
    <Menu>
      <Menu.Item
        key="0"
        onClick={() => {
          setShowAudit(true);
          setAuditId(id);
        }}
      >
        Histórico de atualizações
      </Menu.Item>
    </Menu>
  );
};
const SkusPetz = props => {
  const { setAuditId, setShowAudit } = props;

  return [
    {
      title: 'ID (Portal)',
      dataIndex: 'id',
      key: 'id',
      render: data => (
        <Link
          to={`/skus-petz/${data}`}
          style={{
            border: '0',
            textDecoration: 'underline',
            cursor: 'pointer',
            background: 'transparent',
            color: 'rgba(0, 0, 0, 0.65)'
          }}
        >
          {data}
        </Link>
      )
    },
    {
      title: 'EAN',
      dataIndex: 'ean',
      key: 'ean'
    },
    {
      title: 'Código SKU Petz',
      dataIndex: 'external_id',
      key: 'external_id'
    },
    {
      title: 'Código SKU Zee.Now',
      dataIndex: 'reference',
      key: 'reference'
    },
    {
      title: 'Código Produto Zee.Now',
      dataIndex: 'product_reference',
      key: 'product_reference'
    },
    {
      title: 'Tamanho',
      dataIndex: 'description',
      key: 'description'
    },
    {
      title: 'Peso',
      dataIndex: 'weight',
      key: 'weight'
    },
    {
      title: 'Histórico',
      // dataIndex: 'weight',
      align: 'center',
      key: 'history',
      render: data => {
        return (
          <Dropdown
            overlay={
              <Actions
                setShowAudit={setShowAudit}
                setAuditId={setAuditId}
                id={data.id}
              />
            }
            trigger={['hover']}
          >
            <span>
              <Icon
                type="ellipsis"
                key="ellipsis"
                style={{ fontSize: '30px' }}
              />
            </span>
          </Dropdown>
        );
      }
    }
  ];
};

Actions.propTypes = {
  setShowAudit: PropTypes.func.isRequired,
  setAuditId: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired
};

SkusPetz.propTypes = {
  setShowAudit: PropTypes.func.isRequired,
  setAuditId: PropTypes.func.isRequired
};

export default SkusPetz;
