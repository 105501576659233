import React, { useState, useEffect } from 'react';
import { subscriptionStatus } from '@/src/utils/localeData';
import PropTypes from 'prop-types';
import Tables from '@/src/components/Tables';
// import usePermissions from '@/src/hooks/usePermissions';
// import * as modules from '@/src/globals/permissionsModules';

import { Row, Col, Button, Icon, InputNumber } from 'antd';

const SuscriptionItemsActions = props => {
  const {
    data,
    products,
    handleChangeQty,
    setIsSkip,
    setIsPauseAll,
    setShowPauseModal,
    isEdit,
    setItemInfo,
    // subscriptionData,
    hasPausedItem
  } = props;
  const [itemQty, setItemQty] = useState(data.quantity);

  // const { editPermission } = usePermissions();
  // const managePermission = editPermission(modules.SUBSCRIPTIONS_MANAGE);

  useEffect(() => {
    const updated = [];
    products.forEach(product => {
      const qty =
        product.attributes.id === data.id
          ? itemQty
          : product.attributes.quantity;
      const aux = {
        id: product.attributes.id,
        quantity: qty
      };
      updated.push(aux);
    });
    handleChangeQty(updated);
    // eslint-disable-next-line
  }, [itemQty]);

  useEffect(() => {
    setItemQty(data.quantity);
    // eslint-disable-next-line
  }, [isEdit]);

  const isPaused =
    data.next_cycle_status === 'skipping' ||
    data.next_cycle_status === 'paused';

  const pauseOrSkipItem = isSkipAction => {
    setIsSkip(isSkipAction);
    setIsPauseAll(false);
    setShowPauseModal(true);
    setItemInfo({
      name: data.product_name,
      qty: data.quantity,
      pausedQty: data.paused_quantity,
      canPauseQty: Number(data.quantity) - Number(data.paused_quantity),
      itemId: data.id
    });
  };

  return (
    <div className="table-actions">
      {isEdit ? (
        <>
          <Col>
            <Row>
              <p style={{ fontWeight: 'bold', marginBottom: 5 }}>
                {' '}
                Alterar quantidade
              </p>
            </Row>
            <Row type="flex">
              <Button
                onClick={() => {
                  if (itemQty > 0) setItemQty(itemQty - 1);
                }}
                style={{ width: 32, padding: '0 5px' }}
              >
                <Icon type="minus" />
              </Button>
              <InputNumber
                // max={data.quantity}
                min={0}
                value={itemQty}
                style={{ width: 60, margin: '0 5px' }}
                onChange={e => setItemQty(e)}
              />
              <Button
                onClick={() => {
                  setItemQty(itemQty + 1);
                }}
                style={{ width: 32, padding: '0 5px' }}
              >
                <Icon type="plus" />
              </Button>
            </Row>
          </Col>
        </>
      ) : (
        <>
          {/* <button
              type="button"
              className="table-actions__pause"
              onClick={() => pauseOrSkipItem(false)}
              disabled={isPaused || !managePermission}
              style={
                isPaused
                  ? { cursor: 'default', border: '1px solid #ebebeb' }
                  : {}
              }
            >
              {isPaused ? 'Pausado' : 'Pausar'}
            </button> */}
          <Button
            // type="primary"
            disabled
            onClick={() => {
              pauseOrSkipItem(true);
            }}
            // disabled={
            //   isPaused ||
            //   subscriptionData.status === 'inactive' ||
            //   !managePermission
            // }
          >
            {isPaused && hasPausedItem ? 'Pulado' : 'Pular'}
          </Button>
        </>
      )}
    </div>
  );
};

const handleImgError = event => {
  event.persist();
  event.target.classList.add('hidden');
};

const SubscriptionItemsTable = props => {
  const {
    products,
    setIsSkip,
    setIsPauseAll,
    setShowPauseModal,
    isEdit,
    // subscriptionData,
    hasPausedItem,
    isLoading,
    setEditSubscriptionItems,
    setItemInfo
  } = props;

  const columns = [
    {
      title: 'Foto',
      dataIndex: 'attributes',
      key: 'foto',
      render: record => {
        return (
          <div
            style={{
              width: '40px',
              height: '40px',
              backgroundColor: '#ebebeb',
              borderRadius: 4
            }}
          >
            {record.product_thumb && (
              <img
                style={{ width: '100%', objectFit: 'fill', height: '100%' }}
                src={record.product_thumb}
                alt={record.product_name}
                onError={event => handleImgError(event)}
              />
            )}
          </div>
        );
      }
    },
    {
      title: 'SKU',
      dataIndex: 'attributes.sku_external_id',
      key: 'sku',
      width: '15%'
    },
    {
      title: 'EAN',
      dataIndex: 'attributes.sku_ean',
      key: 'ean',
      width: '20%'
    },
    {
      title: 'Nome Produto',
      dataIndex: 'attributes.product_name',
      key: 'name',
      width: '30%'
    },
    {
      title: 'Próximo Ciclo',
      dataIndex: 'attributes',
      key: 'next_cycle_status',
      width: '10%',
      render: record => {
        const { next_cycle_status: cycleStatus } = record;
        return subscriptionStatus[cycleStatus];
      }
    },
    {
      title: 'Ações',
      align: 'center',
      key: 'actions',
      width: '20%',
      dataIndex: 'attributes',
      render: data => {
        // if (!managePermission) return null;

        return (
          <SuscriptionItemsActions
            data={data}
            products={products}
            handleChangeQty={newItems => setEditSubscriptionItems(newItems)}
            setIsSkip={setIsSkip}
            setIsPauseAll={setIsPauseAll}
            setShowPauseModal={setShowPauseModal}
            isEdit={isEdit}
            // subscriptionData={subscriptionData}
            hasPausedItem={hasPausedItem}
            setItemInfo={setItemInfo}
          />
        );
      }
    }
  ];

  return (
    <Tables
      data={products || []}
      // query={queryDefault}
      // populateTables={getSuspensionListHandler}
      className="even-gray"
      rowKey={record => record.id}
      // contentToShow="hubsSuspension"
      isLoading={isLoading}
      columnsDefaults={columns}
      rowClick={false}
    />
  );
};

SuscriptionItemsActions.propTypes = {
  data: PropTypes.any.isRequired,
  products: PropTypes.any.isRequired,
  // subscriptionData: PropTypes.any.isRequired,
  setIsSkip: PropTypes.func.isRequired,
  setShowPauseModal: PropTypes.func.isRequired,
  isEdit: PropTypes.bool.isRequired,
  setItemInfo: PropTypes.func.isRequired,
  hasPausedItem: PropTypes.bool.isRequired,
  handleChangeQty: PropTypes.func.isRequired,
  setIsPauseAll: PropTypes.func.isRequired
};

SubscriptionItemsTable.propTypes = {
  products: PropTypes.any.isRequired,
  // subscriptionData: PropTypes.any.isRequired,
  setIsSkip: PropTypes.func.isRequired,
  setIsPauseAll: PropTypes.func.isRequired,
  setShowPauseModal: PropTypes.func.isRequired,
  isEdit: PropTypes.bool.isRequired,
  setItemInfo: PropTypes.func.isRequired,
  hasPausedItem: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  setEditSubscriptionItems: PropTypes.func.isRequired
};

export default SubscriptionItemsTable;
