import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Row, Col, Button, Icon, Tooltip } from 'antd';
import handleCall from '@/src/utils/handleCallToCustomer';
import formatCPF from '@/src/utils/formatCPF';

import usePermissions from '@/src/hooks/usePermissions';
import * as modules from '@/src/globals/permissionsModules';

import CustomerQuickInfoStyles from './CustomerQuickInfo.module.scss';

const CustomerQuickInfo = props => {
  const {
    customer: { attributes, id },
    makeCall
  } = props;

  const [copyPhone, setCopyPhone] = useState('Copiar telefone');
  const [copyName, setCopyName] = useState('Copiar nome');

  const { editPermission, routePermission } = usePermissions();
  const ordersPermission = editPermission(modules.ORDERS);
  const customersPermission = routePermission(modules.CUSTOMERS);

  const phoneCopy = type => {
    if (type === 'phone') {
      navigator.clipboard.writeText(attributes.phone_number);
      setCopyPhone('Telefone copiado');
    }
    if (type === 'name') {
      navigator.clipboard.writeText(attributes.name);
      setCopyName('Nome copiado');
    }
  };

  return (
    <section className={CustomerQuickInfoStyles.container}>
      <Row type="flex" justify="space-between" align="middle">
        {customersPermission ? (
          <Col style={{ display: 'flex', alignItems: 'center' }}>
            <Link to={`/clientes/${id}`}>
              <h3 className={CustomerQuickInfoStyles.highlight}>
                {attributes.name}
              </h3>
            </Link>
            <Tooltip placement="rightTop" title={copyName}>
              <Button
                type="link"
                className="order-timeline"
                style={{ marginLeft: 5 }}
                onClick={() => phoneCopy('name')}
              >
                <Icon
                  type="copy"
                  key="phone_copy"
                  style={{ fontSize: '22px' }}
                />
              </Button>
            </Tooltip>
          </Col>
        ) : (
          <h3>{attributes.name}</h3>
        )}
        <Col>
          <p>
            <small>CPF </small>
            {attributes.document
              ? formatCPF(attributes.document)
              : 'Não preenchido'}
          </p>
        </Col>
        <Col style={{ display: 'flex', alignItems: 'center' }}>
          {ordersPermission ? (
            <>
              <Button
                type="link"
                onClick={() =>
                  handleCall({
                    name: attributes.name,
                    phone_number: attributes.phone_number,
                    cb: makeCall
                  })
                }
                className={CustomerQuickInfoStyles.highlight}
              >
                {attributes.phone_number || '-'}
              </Button>
              <Tooltip placement="rightTop" title={copyPhone}>
                <Button
                  type="link"
                  className="order-timeline"
                  onClick={() => phoneCopy('phone')}
                >
                  <Icon
                    type="copy"
                    key="phone_copy"
                    style={{ fontSize: '22px' }}
                  />
                </Button>
              </Tooltip>
            </>
          ) : (
            <p>{attributes.phone_number || '-'}</p>
          )}
        </Col>
        <Col>
          {ordersPermission ? (
            <a
              href={`mailto:${attributes.email}`}
              target="_blank"
              rel="noopener noreferrer"
              // className="text-bold text-blue text-underline"
              style={{ textDecoration: 'underline' }}
              className={CustomerQuickInfoStyles.highlight}
            >
              {attributes.email}
            </a>
          ) : (
            <p>{attributes.email}</p>
          )}
        </Col>
      </Row>
    </section>
  );
};

CustomerQuickInfo.propTypes = {
  customer: PropTypes.shape({
    id: PropTypes.string,
    attributes: PropTypes.shape({
      document: PropTypes.string,
      email: PropTypes.string,
      name: PropTypes.string,
      phone_number: PropTypes.string
    })
  }).isRequired,
  makeCall: PropTypes.func.isRequired
};

export default React.memo(CustomerQuickInfo);
