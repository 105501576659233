import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Table, Pagination, Row, Col } from 'antd';

import formatCurrency from '@/src/utils/formatCurrency';

import customers from './Columns/customers';
import customersInfo from './Columns/customersInfo';
import orders from './Columns/orders';
import referrals from './Columns/referrals';
import users from './Columns/users';
import logUser from './Columns/logUser';
import newAisleModal from './Columns/newAisleModal';
// import newAisle from './Columns/newAisle';
import attributes from './Columns/attributes';
import departments from './Columns/departments';
import expedition from './Columns/expedition';
import shipping, { newAndEditShipping } from './Columns/shipping';
import modalGather from './Columns/modalGather';
import products from './Columns/products';
import hubsList from './Columns/hubs';
import priceTable from './Columns/priceTable';
import deliverers from './Columns/deliverers';
import skusPetz from './Columns/skusPetz';
import logSkuPetz from './Columns/logSkuPetz';
// import Audit from '../Audit/index';

const Tables = props => {
  const {
    contentToShow,
    showExpandedRow,
    showCountFilters,
    showCountText,
    total,
    count,
    history,
    data,
    populateTables,
    query,
    isLoading,
    visiblePaginationUp,
    visiblePaginationDown,
    showSizeChanger,
    hasScroll,
    columnsDefaults,
    rowClick,
    hasAudit,
    setShowAudit
  } = props;
  const scrollNumber = { x: 0, y: 400 };
  const scrollHasNotNumber = { x: 0, y: 0 };

  // const [showAudit, setShowAudit] = useState(false);
  const [auditId, setAuditId] = useState(false);

  let columns;
  let names;
  let typeContentToShow;

  switch (contentToShow) {
    case 'logUser':
      columns = logUser;
      names = '';
      break;
    case 'users':
      columns = users;
      names = 'Usuários';
      typeContentToShow = false;
      break;
    case 'customerInfo':
      columns = customersInfo;
      typeContentToShow = false;
      break;
    case 'customers':
      columns = customers;
      names = 'Clientes';
      typeContentToShow = false;
      break;
    case 'orders':
      columns = orders;
      names = 'Pedidos';
      typeContentToShow = true;
      break;
    case 'referrals':
      columns = referrals;
      names = 'Referrals';
      typeContentToShow = true;
      break;
    case 'newAisleModal':
      columns = newAisleModal;
      typeContentToShow = true;
      break;
    // case 'newAisle':
    //   columns = newAisle;
    //   typeContentToShow = true;
    //   break;
    case 'attributes':
      columns = attributes;
      typeContentToShow = true;
      break;
    case 'departments':
      columns = departments;
      typeContentToShow = true;
      break;
    case 'expedition':
      columns = expedition;
      typeContentToShow = true;
      break;
    case 'shipping':
      columns = shipping;
      typeContentToShow = true;
      break;
    case 'newAndEditShipping':
      columns = newAndEditShipping;
      typeContentToShow = true;
      break;
    case 'modalGather':
      columns = modalGather;
      typeContentToShow = true;
      break;
    case 'products':
      columns = products;
      typeContentToShow = true;
      break;
    case 'hubs':
      columns = hubsList;
      typeContentToShow = true;
      break;
    case 'priceTable':
      columns = priceTable;
      typeContentToShow = true;
      break;
    case 'deliverers':
      columns = deliverers;
      names = 'Entregadores';
      typeContentToShow = false;
      break;
    case 'skusPetz':
      columns = skusPetz({
        setAuditId,
        setShowAudit
      });
      names = 'skus';
      typeContentToShow = false;
      break;
    case 'logSkuPetz':
      columns = logSkuPetz;
      names = '';
      typeContentToShow = false;
      break;
    default:
      columns = columnsDefaults;
  }

  const showExpandedRowValue = record => (
    <p style={{ margin: 0 }}>{record.date}</p>
  );

  const columnSort = () => {
    const urlQuery = history.location.search;
    if (urlQuery.includes('&sort')) {
      const sort = urlQuery.split('&sort')[1];
      const sortParams = {
        column: sort
          .split('=')[0]
          .replace('[', '')
          .replace(']', ''),
        order: sort
          .split('=')[1]
          .replace('desc', 'descend')
          .replace('asc', 'ascend')
      };
      columns.forEach(el => {
        if (el.key === sortParams.column) {
          Object.assign(el, { defaultSortOrder: sortParams.order });
        }
      });
    }
  };
  columnSort();

  const changePagination = (page, perPage) =>
    populateTables({
      query: {
        ...query,
        page,
        perPage
      }
    });
  const changePerPage = (current, perPage) =>
    populateTables({
      query: {
        ...query,
        page: query.perPage !== perPage ? 1 : current,
        perPage
      }
    });

  return (
    <>
      {hasAudit && auditId && hasAudit(auditId)}
      <Row
        type="flex"
        gutter={16}
        justify="space-between"
        style={{ marginBottom: 20 }}
      >
        <Col
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <div className="table-results">
            {showCountFilters && data.length > 0 ? (
              <p data-test-id="filtered-orders-length">
                {showCountText === '' ? (
                  <>
                    <span>{count}</span> {names.toLowerCase()} filtrados
                  </>
                ) : (
                  <>
                    <span>{count}</span> {showCountText}
                  </>
                )}

                {typeContentToShow ? (
                  <>
                    <span>({formatCurrency(total)})</span>
                  </>
                ) : null}
              </p>
            ) : null}
          </div>
        </Col>
        <Col>
          {visiblePaginationUp ? (
            <Row type="flex" gutter={16}>
              <Col>
                <Pagination
                  showSizeChanger={showSizeChanger}
                  onChange={changePagination}
                  onShowSizeChange={changePerPage}
                  pageSize={query.perPage}
                  pageSizeOptions={['10', '20', '30', '50', '100']}
                  total={count}
                  current={query.page}
                />
              </Col>
            </Row>
          ) : null}
        </Col>
      </Row>
      <Table
        dataSource={data}
        columns={columns}
        pagination={false}
        rowKey={key => key.id}
        loading={isLoading}
        size="medium"
        scroll={hasScroll ? scrollNumber : scrollHasNotNumber}
        // TODO remeber to make a logic for this expandedRowRender to show or not show
        expandedRowRender={showExpandedRow ? showExpandedRowValue : null}
        onChange={(pagination, filter, sorter) => {
          const hasSortOrder = () => {
            return `[${sorter.columnKey}]=${sorter.order.replace('end', '')}`;
          };
          const onChangeQuery = {
            query: {
              ...query,
              page: 1,
              sort: sorter.order ? hasSortOrder() : ''
            }
          };
          populateTables(onChangeQuery);
        }}
        onRow={record => {
          if (!rowClick) return false;
          return {
            onClick: () => {
              if (contentToShow === 'expedition') {
                rowClick(record.number, record.id);
              } else {
                rowClick(record.id);
              }
            }
          };
        }}
        rowClassName={rowClick ? 'has-click' : ''}
        style={{ paddingTop: isLoading ? '4rem' : '0' }}
        {...props} //eslint-disable-line
      />
      {visiblePaginationDown ? (
        <Row type="flex" gutter={16} justify="end" style={{ marginTop: 20 }}>
          <Col>
            <Pagination
              showSizeChanger
              pageSize={query.perPage}
              pageSizeOptions={['10', '20', '30', '50', '100']}
              onShowSizeChange={changePerPage}
              current={query.page}
              total={count}
              onChange={changePagination}
            />
          </Col>
        </Row>
      ) : null}
    </>
  );
};

Tables.defaultProps = {
  hasAudit: null,
  setShowAudit: () => {},
  contentToShow: '',
  visiblePaginationUp: false,
  visiblePaginationDown: false,
  showSizeChanger: true,
  showExpandedRow: false,
  showCountFilters: false,
  showCountText: '',
  columnsDefaults: [],
  count: 0,
  total: 0,
  query: {
    page: 1,
    perPage: 20,
    daysAgo: '1',
    search: '',
    sort: '',
    url: '',
    filters: {}
  },
  isLoading: false,
  history: {
    push: () => {},
    location: {
      search: ''
    }
  },
  hasScroll: false,
  populateTables: () => {},
  rowClick: false
};
Tables.propTypes = {
  hasAudit: PropTypes.any,
  setShowAudit: PropTypes.func,
  contentToShow: PropTypes.string,
  visiblePaginationUp: PropTypes.bool,
  visiblePaginationDown: PropTypes.bool,
  showSizeChanger: PropTypes.bool,
  hasScroll: PropTypes.bool,
  showExpandedRow: PropTypes.bool,
  showCountFilters: PropTypes.bool,
  showCountText: PropTypes.string,
  count: PropTypes.number,
  total: PropTypes.number,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  columnsDefaults: PropTypes.arrayOf(PropTypes.shape({})),
  query: PropTypes.shape({
    page: PropTypes.number,
    perPage: PropTypes.number,
    daysAgo: PropTypes.string,
    search: PropTypes.string,
    sort: PropTypes.string,
    url: PropTypes.string,
    filters: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.array])
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
    location: PropTypes.shape({
      search: PropTypes.string
    })
  }),
  isLoading: PropTypes.bool,
  populateTables: PropTypes.func,
  rowClick: PropTypes.oneOfType([PropTypes.func, PropTypes.bool])
};
export default Tables;
