import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Input, Row } from 'antd';
import style from '@/src/pages/Subscriptions/Subscriptions.module.scss';

const CancelSubscriptionModal = props => {
  const {
    handleCancel,
    handleRequestCancel,
    showCancelModal,
    setShowCancelModal,
    setCodeToCancel,
    codeToCancel,
    setIsCodeCancelModal,
    isCodeCancelModal
  } = props;

  return (
    <Modal
      title="CANCELAR ASSINATURA"
      visible={showCancelModal}
      width={400}
      cancelButtonProps={{ className: 'ant-btn-secondary' }}
      className="centered-footer"
      footer={null}
      onCancel={() => setShowCancelModal(false)}
    >
      <div className={style.cancelModal}>
        <p style={{ textAlign: 'center', marginBottom: 20 }}>
          Tem certeza que deseja cancelar assinatura?{' '}
          {isCodeCancelModal
            ? 'Insira o código de confirmação enviado ao gestor de assinaturas.'
            : 'Um código de confirmação será enviado para o seu celular que está cadastrado no Portal.'}
        </p>

        {!isCodeCancelModal ? (
          <Row
            className="flex center"
            style={{ justifyContent: 'space-evenly' }}
          >
            <Button
              onClick={() => setShowCancelModal(false)}
              className="cancel-button"
            >
              Não
            </Button>
            <Button onClick={handleRequestCancel} className="ant-btn-primary">
              Sim
            </Button>
          </Row>
        ) : (
          <>
            <Input
              type="number"
              placeholder="Código de confirmação"
              style={{ marginBottom: '10px' }}
              value={codeToCancel}
              onChange={e => setCodeToCancel(e.target.value)}
            />
            <div
              className="flex center"
              style={{ justifyContent: 'space-evenly' }}
            >
              <Button
                onClick={() => setShowCancelModal(false)}
                className="cancel-button"
              >
                Não
              </Button>
              <Button
                disabled={codeToCancel === '' || !codeToCancel}
                onClick={() => handleCancel()}
                className="ant-btn-primary"
              >
                Sim
              </Button>
            </div>
          </>
        )}
        <div className="flex center">
          <button
            type="button"
            onClick={() => setIsCodeCancelModal(!isCodeCancelModal)}
            className={style.cancelModal__hasCode}
          >
            {isCodeCancelModal
              ? 'Ainda não tenho um código'
              : 'Já tenho um código'}
          </button>
        </div>
      </div>
    </Modal>
  );
};

CancelSubscriptionModal.propTypes = {
  handleCancel: PropTypes.func.isRequired,
  handleRequestCancel: PropTypes.func.isRequired,
  showCancelModal: PropTypes.bool.isRequired,
  setShowCancelModal: PropTypes.func.isRequired,
  setCodeToCancel: PropTypes.func.isRequired,
  codeToCancel: PropTypes.any.isRequired,
  setIsCodeCancelModal: PropTypes.func.isRequired,
  isCodeCancelModal: PropTypes.bool.isRequired
};

export default CancelSubscriptionModal;
