import { apiPortal } from '@/src/api/config';
import queryBuilder from '@/src/utils/queryBuilder';

export const callCustomer = callPayload =>
  apiPortal.post('/calls', callPayload);

export const getCustomers = payload => {
  const getUrl = queryBuilder(payload, '/customers');
  return apiPortal.get(getUrl);
};

export const getCustomer = payload => apiPortal.get(`/customers/${payload}`);

export const getCustomerLastOrders = payload => {
  const getUrl = queryBuilder(payload, 'orders');
  return apiPortal.get(getUrl);
};

export const editCustomer = payload =>
  apiPortal.patch(`/customers/${payload.id}`, payload.value);

export const getCustomerWalletTransactions = payload => {
  const getUrl = queryBuilder(
    payload,
    `/customers/${payload.id}/wallet/wallet_transactions`
  );
  return apiPortal.get(getUrl);
};

export const editCustomerWallet = payload =>
  apiPortal.patch(`/customers/${payload.id}/wallet`, payload.value);

export const logoutCustomer = payload =>
  apiPortal.delete(`/customers_logout/${payload.id}`);

export const deactivateCustomer = payload =>
  apiPortal.delete(`/customers/${payload.id}`);

export const activateCustomer = payload =>
  apiPortal.post('/customers_activation', { id: payload.id });

export const banishReferralCustomer = payload =>
  apiPortal.post(`/customers_referral_ban/`, payload);

export const activateCustomerOnReferral = payload =>
  apiPortal.delete(`/customers_referral_ban/${payload}`);

export const getCustomerAdresses = payload =>
  apiPortal.get(`/customers/${payload}/addresses`);

export const getCustomerCreditCards = payload => {
  const getUrl = queryBuilder(payload, `/customers/${payload.id}/credit_cards`);
  return apiPortal.get(getUrl);
};

export const removeCustomer = payload =>
  apiPortal.delete(`/customers_removal/${payload.id}`);
