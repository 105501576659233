import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import {
  Breadcrumb,
  Row,
  Col,
  Form,
  Card,
  Button,
  Modal,
  Menu,
  Dropdown,
  Icon
} from 'antd';
import PropTypes from 'prop-types';

import {
  getProduct,
  resetState
} from '@/src/store/modules/product-details/slice';
import { setAllProducts } from '@/src/store/modules/products-all/slice';
import {
  getCatalogAttributes,
  setCatalogAttributes
} from '@/src/store/modules/catalog-categories/slice';
import ProductDetailsForm from '@/src/components/Products/ProductDetailsForm';
import SeoForm from '@/src/components/Products/SeoForm';
import AttributesCard from '@/src/components/Products/AttributesCard';
import SkuCard from '@/src/components/Products/SkuCard';
import GiftCard from '@/src/components/Products/GiftCard/index';
import UploadImage from '@/src/components/UploadImage/index';
import Audit from '@/src/components/Audit/index';
import ProductCustomFields from '@/src/components/CustomFields/Product/index';
import useSaveProductEdits from '@/src/hooks/useSaveProductEdit';
import { handleProperties } from '@/src/utils/products';

import usePermissions from '@/src/hooks/usePermissions';
import * as modules from '@/src/globals/permissionsModules';
import { ZEEDOG_STORE, ZEENOW_STORE } from '@/src/globals/constants';

const EditProduct = ({ form }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const {
    getFieldDecorator,
    getFieldValue,
    setFieldsValue,
    validateFields
  } = form;

  const [pageTitle, setPageTitle] = useState('');
  const [thumbnail, setThumbnail] = useState([]);
  const [withoutImage, setWithoutImage] = useState(false);
  const [mainImages, setMainImages] = useState([]);
  const [largeImages, setLargeImages] = useState([]);
  const [experienceImages, setExperienceImages] = useState([]);
  const [ifoodImages, setIfoodImages] = useState([]);
  const [availableProperties, setAvailableProperties] = useState([]); // atributos associados a categoria (principal e secundária) do produto
  const [initialProperties, setInitialProperties] = useState([]);
  const [productProperties, setProductProperties] = useState([]);
  const [propertiesSelected, setPropertiesSelected] = useState([]);
  const [propertiesUnselected, setPropertiesUnselected] = useState([]);
  const [customFields, setCustomFields] = useState([]);
  const [skuChanges, setSkuChanges] = useState([]);
  const [imagesToDelete, setImagesToDelete] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [tags, setTags] = useState([]);
  const [showAudit, setShowAudit] = useState(false);

  // salechannel x sku visibility
  const [salechannel, setSalechannel] = useState([]);

  const { saveAllEdits } = useSaveProductEdits();
  const { editPermission } = usePermissions();

  const catalogPermission = editPermission(modules.CATALOG);

  const { product, isLoading, updated, hubSkus } = useSelector(
    state => state.productDetails
  );
  const { attributes } = useSelector(state => state.catalogCategories);

  useEffect(() => {
    dispatch(getProduct(id));
    dispatch(
      setAllProducts({
        productsList: [],
        count: 0
      })
    );
    return () => {
      dispatch(resetState());
      dispatch(setCatalogAttributes({ attributes: [] }));
    };
  }, [dispatch, id]);

  useEffect(() => {
    if (product.id) {
      const {
        images,
        largeImages: large_images,
        experienceImages: experience_images,
        ifoodImages: ifood_images,
        categoryId,
        extraCategoriesIds,
        // properties,
        // selectedValues,
        // productProperties,
        thumb,
        title,
        productCustomFields
      } = product;
      if (categoryId) {
        const extraCategories =
          extraCategoriesIds?.length > 0 ? extraCategoriesIds : [];
        dispatch(
          getCatalogAttributes({
            query: {
              filters: {
                by_categories: [categoryId, ...extraCategories]
              }
            }
          })
        );
      }

      if (productCustomFields) {
        const fields = productCustomFields.map(item => {
          return {
            id: item.customFieldId.toString(),
            label: item.customFieldName,
            value: item.value,
            type: item.customFieldFieldType,
            media: item.customFieldMediaType
          };
        });
        setCustomFields(fields);
      }

      setPageTitle(title);
      setThumbnail(thumb.url ? [thumb] : []);
      setMainImages(images);
      setLargeImages(large_images);
      setExperienceImages(experience_images);
      setIfoodImages(ifood_images);
    }
  }, [dispatch, product]);

  const handleDeleteImage = imageId => {
    if (imageId) {
      const imagesIds = imagesToDelete;
      imagesIds.push(imageId.toString());
      setImagesToDelete(imagesIds);
    }
  };

  const handlePropertiesUpdate = (selected, unselected) => {
    let newSelected = [...propertiesSelected];
    let newUnselected = [...propertiesUnselected];

    productProperties.forEach(property => {
      if (selected.includes(property.id)) {
        if (!newSelected.includes(property.id) && !property.selected_id)
          newSelected.push(property.id);
        if (newUnselected.includes(property.id))
          newUnselected = newUnselected.filter(item => item !== property.id);
      } else if (unselected.includes(property.id)) {
        if (!newUnselected.includes(property.id) && property.selected_id)
          newUnselected.push(property.id);
        if (newSelected.includes(property.id))
          newSelected = newSelected.filter(item => item !== property.id);
      }
    });

    const newValue = selected.filter(
      item => !productProperties.find(property => property.id === item)
    );
    if (newValue.length) newSelected = [...newSelected, ...newValue];

    setPropertiesSelected(newSelected);
    setPropertiesUnselected(newUnselected);
  };

  useEffect(() => {
    if (product.id && attributes?.length > 0) {
      setAvailableProperties(attributes);

      const productNewProperties = handleProperties(product, attributes);
      setProductProperties(productNewProperties);

      const newSelected = productNewProperties
        .filter(item => item.selected_id)
        .map(item => item.id);

      setPropertiesSelected(newSelected);

      if (initialProperties.length === 0) {
        setInitialProperties(newSelected);
      }

      const newUnselected = initialProperties.filter(
        item => !newSelected.includes(item)
      );
      setPropertiesUnselected(newUnselected);
    }
    // eslint-disable-next-line
  }, [product, attributes]);

  const changeAttributes = value => {
    dispatch(
      getCatalogAttributes({
        query: {
          filters: {
            by_categories: value
          }
        }
      })
    );
  };

  const actions = (
    <Menu>
      <Menu.Item key="0" onClick={() => setShowAudit(true)}>
        Histórico de atualizações
      </Menu.Item>
    </Menu>
  );

  const actionsExtra = (
    <Dropdown overlay={actions} trigger={['click']}>
      <span>
        <Icon type="ellipsis" key="ellipsis" style={{ fontSize: '30px' }} />
      </span>
    </Dropdown>
  );

  const handleEditSkuInfo = ({ newValue, type, skuId, infos }) => {
    let newSkuData = skuChanges;

    const alreadyInArray = newSkuData.filter(sku => sku.id === skuId);
    if (!alreadyInArray.length) {
      newSkuData.push({
        id: skuId,
        name: infos.name,
        subscriptable: infos.subscriptable,
        active: infos.active
      });
    }

    if (newSkuData.length) {
      const hasInArray = newSkuData.filter(sku => sku.id === skuId)[0];
      // console.log(hasInArray);
      newSkuData = newSkuData.filter(sku => sku.id !== skuId);
      newSkuData.push({ ...hasInArray, id: skuId, [type]: newValue });
    }

    setSkuChanges(newSkuData);
  };

  const handleModalOk = () => {
    validateFields((err, values) => {
      if (err) setShowModal(false);
      if (!err) {
        setPageTitle(values.title);
        const allValues = { ...values, search_metadata: tags.join(',') };
        saveAllEdits(
          id,
          allValues,
          thumbnail,
          mainImages,
          largeImages,
          experienceImages,
          ifoodImages,
          imagesToDelete,
          propertiesSelected,
          propertiesUnselected,
          customFields,
          skuChanges,
          hubSkus
        );
        setShowModal(false);
      }
    });
  };

  const handleImageError = e => {
    e.persist();
    if (e.type === 'error') {
      e.target.alt = '';
    }
  };

  return (
    <>
      <Audit
        id={id}
        title="PRODUTO"
        type="Product"
        visible={showAudit}
        setVisible={setShowAudit}
      />
      <Modal
        title="SALVAR ALTERAÇÕES NO APP"
        okText="Confirmar"
        centered
        className="centered-footer"
        onOk={handleModalOk}
        onCancel={() => setShowModal(false)}
        visible={showModal}
        bodyStyle={{ padding: '16px 24px 36px' }}
        width={400}
      >
        <span>
          Tem certeza que deseja salvar as alterações? Todas as mudanças feitas
          serão espelhadas no app.
        </span>
      </Modal>
      <Row type="flex" justify="space-between" align="middle">
        <Col span={20}>
          <h1>{pageTitle}</h1>
        </Col>
      </Row>
      <Breadcrumb separator="" style={{ marginBottom: 20 }}>
        <Breadcrumb.Item>
          <Link to="/produtos">Produtos</Link>
          <Breadcrumb.Separator>&gt; </Breadcrumb.Separator>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{pageTitle}</Breadcrumb.Item>
      </Breadcrumb>
      <Form>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            borderRadius: '16px',
            border: '7px solid #ebebeb',
            backgroundColor: '#ebebeb',
            marginBottom: '60px'
          }}
        >
          <Row gutter={7} className="two-columns card-margin form-edit-produto">
            <Col
              className="no-shadow"
              span={12}
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              <Card
                className={`no-shadow box card-margin ${
                  ZEEDOG_STORE ? 'product-image-upload' : ''
                }`}
                title="IMAGEM THUMB"
                loading={isLoading}
              >
                {ZEENOW_STORE && (
                  <span className="card-subtitle">
                    *Tamanho recomendado: 400 x 400
                  </span>
                )}
                <UploadImage
                  isMultiple
                  type="thumbnail"
                  uploadImage={thumbnail}
                  setUploadImage={setThumbnail}
                  withoutImage={withoutImage}
                  setWithoutImage={setWithoutImage}
                  handleDeleteImage={handleDeleteImage}
                  editPermission={catalogPermission}
                  handleImageError={handleImageError}
                />
              </Card>
              {ZEEDOG_STORE && (
                <Card
                  className="no-shadow box card-margin product-image-upload"
                  title="IMAGENS HOVER"
                  loading={isLoading}
                >
                  <UploadImage
                    isMultiple
                    type="carousel"
                    uploadImage={largeImages}
                    setUploadImage={setLargeImages}
                    withoutImage={withoutImage}
                    setWithoutImage={setWithoutImage}
                    handleDeleteImage={handleDeleteImage}
                    editPermission={catalogPermission}
                    handleImageError={handleImageError}
                  />
                </Card>
              )}
              <Card
                className={`no-shadow box card-margin ${
                  ZEEDOG_STORE ? 'product-image-upload' : ''
                }`}
                title={ZEEDOG_STORE ? 'IMAGENS MAIN' : 'IMAGENS CARROSSEL'}
                loading={isLoading}
              >
                {ZEENOW_STORE && (
                  <span className="card-subtitle">
                    *Tamanho recomendado: 1000 x 1000
                  </span>
                )}
                <UploadImage
                  isMultiple
                  type="carousel"
                  uploadImage={mainImages}
                  setUploadImage={setMainImages}
                  withoutImage={withoutImage}
                  setWithoutImage={setWithoutImage}
                  handleDeleteImage={handleDeleteImage}
                  editPermission={catalogPermission}
                  handleImageError={handleImageError}
                />
              </Card>
              <Card
                className={`no-shadow box card-margin ${
                  ZEEDOG_STORE ? 'product-image-upload' : ''
                }`}
                title={
                  ZEEDOG_STORE
                    ? 'IMAGENS LIFESTYLE'
                    : 'IMAGENS CONTEÚDO ESPECIAL'
                }
                loading={isLoading}
              >
                {ZEENOW_STORE && (
                  <span className="card-subtitle">
                    *Tamanho recomendado: altura livre x 800
                  </span>
                )}
                <UploadImage
                  isMultiple
                  type="special_content"
                  uploadImage={experienceImages}
                  setUploadImage={setExperienceImages}
                  withoutImage={withoutImage}
                  setWithoutImage={setWithoutImage}
                  handleDeleteImage={handleDeleteImage}
                  editPermission={catalogPermission}
                  handleImageError={handleImageError}
                />
              </Card>
              {ZEENOW_STORE && (
                <Card
                  className="no-shadow box card-margin"
                  title="IMAGEM IFOOD"
                  loading={isLoading}
                >
                  <span className="card-subtitle">
                    *Tamanho obrigatório: 1060 x 800
                  </span>
                  <UploadImage
                    isMultiple
                    type="ifood"
                    uploadImage={ifoodImages}
                    setUploadImage={setIfoodImages}
                    withoutImage={withoutImage}
                    setWithoutImage={setWithoutImage}
                    handleDeleteImage={handleDeleteImage}
                    editPermission={catalogPermission}
                    handleImageError={handleImageError}
                  />
                </Card>
              )}
              <Card
                className="no-shadow box"
                title="SEO (WEB)"
                loading={isLoading}
                style={{ flexGrow: 1 }}
              >
                <SeoForm
                  content={product}
                  getFieldDecorator={getFieldDecorator}
                  setFieldsValue={setFieldsValue}
                  editPermission={catalogPermission}
                />
              </Card>
            </Col>
            <Col className="no-shadow" span={12}>
              <Card
                className="no-shadow box"
                title="DETALHES"
                bordered={false}
                loading={isLoading}
                extra={catalogPermission && actionsExtra}
                style={{ height: '100%', flexGrow: 1 }}
              >
                <ProductDetailsForm
                  content={product}
                  getFieldDecorator={getFieldDecorator}
                  getFieldValue={getFieldValue}
                  setFieldsValue={setFieldsValue}
                  changeAttributes={changeAttributes}
                  editPermission={catalogPermission}
                  tags={tags}
                  setTags={setTags}
                  setSalechannel={setSalechannel}
                />
              </Card>
            </Col>
          </Row>
          <Row gutter={7} className="two-columns card-margin form-edit-produto">
            <Col
              className="no-shadow"
              span={12}
              style={{ display: 'flex', flexGrow: 1 }}
            >
              <AttributesCard
                properties={availableProperties}
                productProperties={productProperties}
                handleUpdate={handlePropertiesUpdate}
                loading={isLoading}
                editPermission={catalogPermission}
                isFilterable
              />
            </Col>
            <Col
              className="no-shadow"
              span={12}
              style={{ display: 'flex', flexGrow: 1 }}
            >
              <AttributesCard
                properties={availableProperties}
                productProperties={productProperties}
                handleUpdate={handlePropertiesUpdate}
                loading={isLoading}
                editPermission={catalogPermission}
                isFilterable={false}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <ProductCustomFields
              categoryId={form.getFieldValue('category_id')}
              customFields={customFields}
              setCustomFields={setCustomFields}
              getFieldDecorator={getFieldDecorator}
            />
          </Row>
          <Row gutter={16}>
            {product?.skus?.map(sku => {
              return (
                <SkuCard
                  data={sku}
                  key={sku.id}
                  updateInfo={handleEditSkuInfo}
                  loading={isLoading}
                  editPermission={catalogPermission}
                  salechannel={salechannel}
                />
              );
            })}
          </Row>
          <Row gutter={16}>
            <GiftCard product={product} />
          </Row>
        </div>
        {catalogPermission && (
          <footer className="footer-form-save">
            <Button
              className="ant-btn ant-btn-primary"
              style={{ left: '80%' }}
              onClick={() => setShowModal(true)}
              loading={updated}
            >
              Salvar Alterações
            </Button>
          </footer>
        )}
      </Form>
    </>
  );
};

// handleEditSkuInfo.propTypes = {
//   newValue: PropTypes.any.isRequired,
//   type: PropTypes.string.isRequired,
//   skuId: PropTypes.any.isRequired,
//   infos: PropTypes.any.isRequired
// }

EditProduct.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    getFieldValue: PropTypes.func,
    validateFields: PropTypes.func,
    setFieldsValue: PropTypes.func
  }).isRequired
};

const NewProductFrom = Form.create({ name: 'EditProduct' })(EditProduct);

export default NewProductFrom;
