import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'throttle-debounce';
import {
  Row,
  Breadcrumb,
  Col,
  Card,
  Form,
  Select,
  Button,
  Menu,
  Dropdown,
  Icon,
  Input
} from 'antd';
import PropTypes from 'prop-types';

import UploadImage from '@/src/components/UploadImage';
import Audit from '@/src/components/Audit/index';

import { getProducts } from '@/src/store/modules/products/slice';
import { getCategories } from '@/src/store/modules/categories/slice';
import { getManufacturers } from '@/src/store/modules/manufacturers/slice';
import {
  getHighlight,
  updateHighlight,
  archiveHighlight,
  unarchiveHighlight
} from '@/src/store/modules/highlights/slice';

import { translateHighlightsLabel } from '@/src/utils/localeData';
import { handleSelectSearch } from '@/src/utils/searchUtils';

const { Option } = Select;

const EditHighlight = props => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { form } = props;
  const {
    getFieldDecorator,
    validateFields,
    setFieldsValue,
    getFieldsValue
  } = form;
  const fieldValues = getFieldsValue(['type', 'type_value', 'search_slug']);
  const [uploadImage, setUploadImage] = useState([]);
  const [withoutImage, setWithoutImage] = useState(false);
  // const [uploadWebImage, setUploadWebImage] = useState([]);
  // const [withoutWebImage, setWithoutWebImage] = useState(false);
  const [type, setType] = useState('');
  const [typeOptions, setTypeOptions] = useState([]);
  const [typeValue, setTypeValue] = useState();
  const [showAudit, setShowAudit] = useState(false);

  const { products } = useSelector(state => state.products);
  const { categories } = useSelector(state => state.categories);
  const { manufacturers } = useSelector(state => state.manufacturers);
  const { highlight, isLoading } = useSelector(state => state.highlights);

  const delayedProductsQuery = useRef(
    debounce(200, value =>
      dispatch(getProducts({ query: { search: value, perPage: 100 } }))
    )
  ).current;

  const checkIfHasImage = () => {
    setWithoutImage(uploadImage.length === 0);
    // setWithoutWebImage(uploadWebImage.length === 0);
    // if (uploadImage.length === 0 || uploadWebImage.length === 0) return false;
    if (uploadImage.length === 0) return false;
    return true;
  };

  const handleSubmitWithAllValues = values => {
    if (checkIfHasImage()) {
      const removeData = dataString =>
        dataString.replace(/data:image\/[\w]+;base64,/g, '');

      let imageChange = '';
      // let webImageChange = '';

      if (uploadImage.includes('data:image')) {
        imageChange = removeData(uploadImage);
      }

      // if (uploadWebImage.includes('data:image')) {
      //   webImageChange = removeData(uploadWebImage);
      // }

      const data = {
        highlightable_type: values.type
        // search_slug: values.search_slug
      };

      if (typeValue) data.highlightable_id = Number(typeValue);

      if (imageChange !== '') data.image = imageChange;
      // if (webImageChange !== '') data.web_image = webImageChange;

      const payload = {
        id,
        data
      };
      dispatch(updateHighlight(payload));
    }
  };

  const onSubmit = () => {
    validateFields((err, values) => {
      if (err) {
        checkIfHasImage();
        return;
      }
      if (!err) {
        handleSubmitWithAllValues(values);
      }
    });
  };

  const handleTypeChange = value => {
    setType(value);
    setFieldsValue({
      type_value: undefined
    });
  };

  const handleTypeValueSearch = value => {
    if (type !== 'product') return;
    if (value) {
      delayedProductsQuery(value);
    } else {
      setTypeOptions([]);
    }
  };

  useEffect(() => {
    dispatch(getHighlight(id));
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (highlight?.attributes) {
      const {
        image,
        highlightable_type,
        highlightable_name,
        search_slug
        // web_image
      } = highlight.attributes;
      setUploadImage(image);
      // setUploadWebImage(web_image);
      const currentType = highlightable_type?.toLowerCase();
      setType(currentType);
      setFieldsValue({
        type_value: currentType === 'category' ? undefined : highlightable_name,
        search_slug
      });
    }
    // eslint-disable-next-line
  }, [highlight]);

  useEffect(() => {
    if (type === 'product') {
      setTypeOptions([]);
    } else if (type === 'category') {
      setTypeOptions(categories);
      dispatch(getCategories({ query: { perPage: 1000 } }));
    } else if (type === 'manufacturer') {
      setTypeOptions(manufacturers);
      dispatch(getManufacturers({ query: { perPage: 1000 } }));
    }
    // eslint-disable-next-line
  }, [type]);

  useEffect(() => {
    if (type === 'product') {
      setTypeOptions(products);
    } else if (type === 'manufacturer') {
      setTypeOptions(manufacturers);
    } else if (type === 'category') {
      const { highlightable_id, highlightable_type } = highlight.attributes;
      setTypeOptions(categories);
      const category = categories.find(
        item => item.attributes.internal_id === highlightable_id
      );
      if (category && highlightable_type === 'Category' && !typeValue) {
        setFieldsValue({
          type_value: `${category.attributes.name} para ${category.attributes.department_name}`
        });
        setTypeValue(category.id);
      }
    }
    // eslint-disable-next-line
  }, [products, categories, manufacturers]);

  const handleArchivation = () => {
    const payload = {
      ids: [Number(id)]
    };
    if (highlight?.attributes?.archived_at) {
      dispatch(unarchiveHighlight(payload));
    } else {
      dispatch(archiveHighlight(payload));
    }
  };

  const actions = (
    <Menu>
      <Menu.Item key="0" onClick={() => setShowAudit(true)}>
        Histórico de atualizações
      </Menu.Item>
      <Menu.Item key="1" onClick={handleArchivation}>
        {highlight?.attributes?.archived_at ? 'Desarquivar' : 'Arquivar'}
      </Menu.Item>
    </Menu>
  );

  const actionsExtra = (
    <Dropdown overlay={actions} trigger={['click']}>
      <span>
        <Icon type="ellipsis" key="ellipsis" style={{ fontSize: '30px' }} />
      </span>
    </Dropdown>
  );

  return (
    <>
      <Audit
        id={id}
        title="DESTAQUE"
        type="Highlight"
        visible={showAudit}
        setVisible={setShowAudit}
      />
      <Row type="flex" justify="space-between" align="middle">
        <Col span={20}>
          <h1>Editar Destaque</h1>
        </Col>
      </Row>
      <Breadcrumb separator="" style={{ marginBottom: 24 }}>
        <Breadcrumb.Item>
          <Link to="/destaques">Destaques</Link>
          <Breadcrumb.Separator>&gt; </Breadcrumb.Separator>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Editar destaque</Breadcrumb.Item>
      </Breadcrumb>
      <div
        style={{
          borderRadius: '16px',
          border: '7px solid #ebebeb',
          minHeight: '48px'
        }}
      >
        <Card
          className="no-shadow"
          title="DETALHES DO DESTAQUE"
          extra={actionsExtra}
        >
          <Row>
            <Col span={10}>
              <Form>
                <Row style={{ display: 'flex', flexDirection: 'column' }}>
                  <Col>
                    <Form.Item label="Tipo">
                      {getFieldDecorator('type', {
                        initialValue: highlight?.attributes?.highlightable_type?.toLowerCase(),
                        rules: [
                          {
                            required: true,
                            message: 'Selecione um tipo'
                          }
                        ]
                      })(
                        <Select
                          placeholder="Selecione um tipo"
                          onChange={handleTypeChange}
                        >
                          <Option value="product">Produto</Option>
                          <Option value="category">Categoria</Option>
                          <Option value="manufacturer">Fabricante</Option>
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item
                      label={
                        translateHighlightsLabel[
                          type ||
                            highlight?.attributes?.highlightable_type?.toLowerCase()
                        ]
                      }
                    >
                      {getFieldDecorator('type_value', {
                        rules: [
                          {
                            required: true,
                            message: `Selecione ${
                              translateHighlightsLabel[type] === 'Categoria'
                                ? 'uma'
                                : 'um'
                            } ${translateHighlightsLabel[type]}`
                          }
                        ]
                      })(
                        <Select
                          showSearch
                          placeholder="Buscar"
                          defaultActiveFirstOption={false}
                          showArrow={false}
                          filterOption={
                            type === 'product' ? false : handleSelectSearch
                          }
                          onSearch={handleTypeValueSearch}
                          onChange={value => setTypeValue(value)}
                          notFoundContent={
                            type === 'product'
                              ? 'Digite o nome do produto'
                              : null
                          }
                        >
                          {typeOptions.map(item => {
                            return (
                              <Option key={item.id}>
                                {`${item.attributes.name}
                                ${
                                  item.type === 'category'
                                    ? ` para ${item.attributes.department_name}`
                                    : ''
                                }`}
                              </Option>
                            );
                          })}
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item label="Slug">
                      {getFieldDecorator('search_slug', {
                        rules: [
                          {
                            required: true,
                            message: 'Digite o slug do destaque'
                          }
                        ]
                      })(
                        <Input placeholder="URL do destaque na web" disabled />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
          <Row>
            <Col>
              <strong
                style={{
                  display: 'block',
                  marginBottom: 8,
                  color: '#000000D9'
                }}
              >
                Imagem App
              </strong>
              <UploadImage
                setUploadImage={setUploadImage}
                uploadImage={uploadImage || []}
                withoutImage={withoutImage}
                setWithoutImage={setWithoutImage}
                recommendedSize={[600, 383]}
              />
            </Col>
            {/* <Col>
              <strong
                style={{
                  display: 'block',
                  marginBottom: 8,
                  color: '#000000D9'
                }}
              >
                Imagem Web
              </strong>
              <UploadImage
                setUploadImage={setUploadWebImage}
                uploadImage={uploadWebImage || []}
                withoutImage={withoutWebImage}
                setWithoutImage={setWithoutWebImage}
                recommendedSize={[600, 383]}
              />
            </Col> */}
          </Row>
          <Row style={{ marginTop: 24 }}>
            <Button
              className="ant-btn ant-btn-primary"
              onClick={onSubmit}
              disabled={
                !fieldValues.type ||
                !fieldValues.type_value ||
                // !fieldValues.search_slug ||
                !uploadImage.length
              }
              loading={isLoading}
            >
              Salvar
            </Button>
          </Row>
        </Card>
      </div>
    </>
  );
};

EditHighlight.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    validateFields: PropTypes.func,
    setFieldsValue: PropTypes.func,
    getFieldsValue: PropTypes.func
  }).isRequired
};

const EditHighlightForm = Form.create({ name: 'EditHighlight' })(EditHighlight);

export default EditHighlightForm;
